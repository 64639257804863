import React from 'react';
import Header from "./components/Header";
import Menubar from "./components/Menubar";
import Markdown from "./components/Markdown";
import NotFound from "./components/NotFound";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';


class App extends React.Component {
    render() {
        return (
            <Router>
                <Header/>
                <div className='content-root'>
                    <Menubar/>
                    <div className='content'>
                        <Switch>
                            <Route exact path='/' component={Markdown}/>
                            <Route component={NotFound}/>
                        </Switch>
                    </div>
                </div>
            </Router>
        )
    };
}

export default App;
