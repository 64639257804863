import React from 'react';


class NotFound extends React.Component {
    render() {
        return (
            <div className="notice-outer">
                <div className="notice-middle">
                    <div className="notice-inner">
                        <h1>404</h1>
                        <p>Page not found</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default NotFound;