import React from 'react';
import {withRouter} from "react-router";
import ReactMarkdown from "react-markdown";
import gfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import PropTypes from "prop-types";
import {fetchMarkdownActionCreator} from "../features/markdown/markdownSlice";
import {connect} from 'react-redux';


class Markdown extends React.Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.forceFetchMarkdown();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            this.forceFetchMarkdown();
        }
    }

    forceFetchMarkdown() {
        let pathname = this.props.location.pathname;
        if (pathname === '/') {
            pathname = '/index';
        }
        this.props.dispatch(fetchMarkdownActionCreator(pathname));
    }

    render() {
        return (
            <ReactMarkdown components={this.components} remarkPlugins={[gfm]} rehypePlugins={[rehypeRaw]}
                           children={this.props.markdown}/>
        )
    }

}

const mapStateToProps = state => ({
    pathname: state.fetchMarkdown.pathname,
    markdown: state.fetchMarkdown.markdown
});
export default connect(mapStateToProps, null)(withRouter(Markdown));
