import React from 'react';
import Sticky from 'react-stickynode';


class Menubar extends React.Component {
    render() {
        return (
            // <Sticky top='.header' className='menubar' innerClass='menubar'>
            <div className='menubar'>
                <div className="menu section"/>
            </div>
            // </Sticky>
        )
    }
}

export default Menubar;