import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {fetchMarkdownActionCreator} from "../features/markdown/markdownSlice";

class Header extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='header'>
                <div className='left'>
                    <h1>Niche Robotics</h1>
                    <ul>
                        <li>
                            <Link to={"/"} onClick={() => this.props.dispatch(fetchMarkdownActionCreator('/index'))}></Link>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
});
export default connect(mapStateToProps, null)(Header)